import { useEffect } from "react";
import { FaUserCheck, FaUserSlash } from "react-icons/fa";
import { config } from "../constants";
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";
import ReactTooltip from "react-tooltip";

const tempStateId = uuidv4();

export default function Auth() {

  const [cookies] = useCookies(["user"]);

  useEffect(() => {
    ReactTooltip.rebuild();
}, [cookies])

  if (cookies.user != null) {
    
    let dropdown = cookies.user.email + "<br />" + cookies.user.disc_id;

    return (
      <div className="userNameAvatarContainer">
        <div className="userName" data-tip={dropdown}>
          {cookies.user.alias}
        </div>
        <a href={config.DISCORDAUTHAPI + tempStateId}>
          <FaUserCheck
            id="avatar"
            className={cookies.user != null ? "fa-user" : "fa-guest"}
            size="40px"
          ></FaUserCheck>
        </a>
      </div>
    );
  } else {
    return (
      <div className="userNameAvatarContainer">
        <div className="userName">Login</div>

        <a href={config.DISCORDAUTHAPI + tempStateId}>
          <FaUserSlash
            id="avatar"
            className={cookies.user != null ? "fa-user" : "fa-guest"}
            size="40px"
          ></FaUserSlash>
        </a>
      </div>
    );
  }
}
