import React from "react";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Terms from "./pages/Terms";
import Customer from "./pages/Customer";
import Captcha from "./pages/Captcha";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Purchase from "./pages/Purchase";
import { CookiesProvider } from "react-cookie";
import SideBar from "./components/SideBar";
import ReactTooltip from 'react-tooltip';
import Auth from "./pages/Auth";
import Checkout from "./pages/Checkout";
import Discord from "./pages/Discord";
import Download from "./pages/Download";

const isMobile = window.screen.width < 770 ? true: false;


export default function App() {
  return (
    <CookiesProvider>
      <ReactTooltip html={true} place="bottom" disable={isMobile} />
      <Router>
        <SideBar />
        <Header />

        <Switch>
          <Route exact path="/">
            <Home />
          </Route>

          <Route exact path="/home">
            <Home />
          </Route>

          <Route exact path="/auth">
            <Auth />
          </Route>

          <Route exact path="/terms">
            <Terms />
          </Route>

          <Route exact path="/download">
            <Download />
          </Route>

          <Route exact path="/purchase">
            <Purchase />
          </Route>

          <Route exact path="/captcha-purchase">
            <Captcha />
          </Route>
          
          <Route exact path="/customer">
            <Customer />
          </Route>

          <Route exact path="/checkout">
            <Checkout />
          </Route>

          <Route exact path="/discord">
            <Discord />
          </Route>


        </Switch>
        <Footer />
      </Router>
    </CookiesProvider>
  );
}

