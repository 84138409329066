
const prod = {
    DISCORDAUTHAPI: "https://discord.com/api/oauth2/authorize?client_id=811706511730016306&redirect_uri=https%3A%2F%2Fhawkgen.co%2Fdiscordapi&response_type=code&scope=identify%20email&state=",

    HAWKAPI: "https://hawkgen.co/hawk-api/auth/?state=",

    HAWKDOWNLOAD: "https://hawkgen.co/hawk-api/download/?",

    STRIPECHECKOUT: "https://hawkgen.co/stripe/checkout/?",

    STRIPECUSTOMER: "https://hawkgen.co/stripe/customer/?",

    HAWKUPDATENEW: "https://hawkgen.co/checknew?session="
}

const dev = {
    DISCORDAUTHAPI: "https://discord.com/api/oauth2/authorize?client_id=811706511730016306&redirect_uri=https%3A%2F%2Fhawkgen.co%2Fdiscordapi&response_type=code&scope=identify%20email&state=",

    HAWKAPI: "https://asolo.herokuapp.com/https://hawkgen.co/hawk-api/auth/?state=",

    HAWKDOWNLOAD: "https://hawkgen.co/hawk-api/download/?",

    STRIPECHECKOUT: "https://hawkgen.co/stripe/checkout/?",

    STRIPECUSTOMER: "https://hawkgen.co/stripe/customer/?",

    HAWKUPDATENEW: "https://asolo.herokuapp.com/https://hawkgen.co/checknew?session="

}

export const config = process.env.NODE_ENV === 'development' ? dev : prod;

