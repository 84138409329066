import React from "react";
import { config } from "../constants";
import { useCookies } from "react-cookie";
import toast, { Toaster } from "react-hot-toast";

export default function Customer() {
  const [cookie] = useCookies(["user"]);

  const customButton = {
    width: "60%",
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0%)",
  };

  function checkLogin() {
    if (cookie.user == null) {
      toast("Please login and authenticate via Discord");
    } else {
      const link =
        config.STRIPECUSTOMER +
        "disc_id=" +
        cookie.user.disc_id +
        "&token=" +
        cookie.user.token;

      window.open(link, "_blank");
    }
  }
  return (
    <div className="content">
      <Toaster
        toastOptions={{
          className: "",
          duration: 3000,
          style: {
            border: "2px solid #00cc00",
            padding: "16px",
            color: "#00cc00",
            background: "#181818",
          },
        }}
        containerStyle={{
          position: "absolute",
        }}
      />
      <div className="single-item-customer">
        <div className="right-set customer">
          <div className="subname subname-customer">Customer Dashboard</div>
          <div className="description-customer">
            <p>
              Update your payment method
              <br />
              <br />
              Manage your subscription
              <br />
              <br />
              View invoice history
            </p>
          </div>

          <button style={customButton} onClick={() => checkLogin()}>
            Go to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
}
