import { useEffect, useState } from "react";
import { config } from "../constants";
import { useCookies } from "react-cookie";
import { useLocation } from "react-router-dom";

export default function Auth() {
  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const state = params.get("state");
  const redirect = params.get("redirect");

  const [readyForRedirect, setReady] = useState(false);
  const [, setCookie] = useCookies(["user"]);
  const [downloadCookie] = useCookies(["download"]);

  function updateUser(data) {
    setCookie("user", data, { path: "/", maxAge: 1800, secure: true });
    setTimeout(() => {setReady(true);}, 1000);
  }

  function redirectIt() {
    const url = "https://hawkgen.co/";

      if (redirect != null) {
        window.location = url + redirect;
      } 
      
      else if (downloadCookie.download != null){
        if(downloadCookie.download.download === true){
          window.location = url + "download";
        }
      }
      else {
        window.location = url;
      }
  }

  useEffect(() => {
    if (state != null) {
      fetch(config.HAWKAPI + state)
        .then((response) => response.json())
        .then((data) => updateUser(data));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (readyForRedirect){
      redirectIt();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyForRedirect]);

  return (
    <div>
      <img className="imageStyle" src="/images/logo.svg" alt="HawkGen" />
    </div>
  );
}
