import React from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import {
  FaHome,
  FaShoppingCart,
  FaUserCog,
  FaNewspaper,
  FaRobot,
} from "react-icons/fa";

const iconSize = "max(25px, 3vw)";

export default function Sidebar() {
  const [cookie] = useCookies(["user"]);
  
  let displayType = "";

  if (cookie.user == null) {
    displayType = "none";
  }

  return (
    <div className="sidebar">
      <div className="sidebar-item-container">
        <div className="sidebar-row">
          <Link id="home" to="/">
            <FaHome className="sidebar-icon" size={iconSize} data-tip="Home" />
          </Link>
        </div>

        <div className="sidebar-row">
          <Link id="purchase" to="/purchase">
            <FaShoppingCart
              className="sidebar-icon"
              size={iconSize}
              data-tip="Purchase or Renew"
            />
          </Link>
        </div>

        <div className="sidebar-row" style={{ display: displayType }}>
          <Link id="captcha-purchase" to="/captcha-purchase">
            <FaRobot
              className="sidebar-icon"
              size={iconSize}
              data-tip="Captcha"
            />
          </Link>
        </div>

        <div className="sidebar-row">
          <Link id="customer" to="/customer">
            <FaUserCog
              className="sidebar-icon"
              size={iconSize}
              data-tip="Customer Portal"
            />
          </Link>
        </div>

        <div className="sidebar-row">
          <Link id="terms" to="/terms">
            <FaNewspaper
              className="sidebar-icon"
              size={iconSize}
              data-tip="Terms + Conditions"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}
