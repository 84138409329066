import React from "react";
import { config } from "../constants";
import { useCookies } from "react-cookie";
import toast, { Toaster } from "react-hot-toast";

export default function Purchase() {
  const [cookie] = useCookies(["user"]);

  const checkLicStatus = (isNew) => {
    let prod = "new";
    if (!isNew) {
      prod = "returning";
    }

    if (cookie.user == null) {
      toast("Please login and authenticate via Discord");
      return;
    }

    if (cookie.user.licenced === 1) {
      toast("You already have an active licence");
      return;
    }

    if (cookie.user.stock === 0 && isNew) {
      toast("Out of stock");
      return;
    }

    fetch(
      config.STRIPECHECKOUT +
        "disc_id=" +
        cookie.user.disc_id +
        "&token=" +
        cookie.user.token +
        "&prod=" +
        prod
    )
      .then((response) => response.text())
      .then((data) => window.location.replace(data));
  };

  function RenderNew() {
    return (
      <div className="content">
        <Toaster
          toastOptions={{
            className: "",
            duration: 3000,
            style: {
              border: "2px solid #00cc00",
              padding: "16px",
              color: "#00cc00",
              background: "#181818",
            },
          }}
          containerStyle={{
            position: "absolute",
          }}
        />
        <div className="single-item">
          <div className="left-set">
            <img src="./images/logo.svg" alt="" />
          </div>
          <div className="right-set">
            <div className="subname">New Subscriber</div>
            <div className="price">
              <span className="pricesmall">£ </span>20
              <span className="pricesmall"> /per month</span>
            </div>
            <div className="description">
              <p>
                Cancel and restart any time
                <br />
                <br />
                £5 restart fee
                <br />
                <br />
                One-off purchase fee of £79.99
              </p>
            </div>
            <button onClick={() => checkLicStatus(true)}>Buy Now</button>
            <div className="subdescription">
            <p>
            By clicking Buy Now you agree to the terms found <a rel="noreferrer" target="_blank" href="https://hawkgen.co/terms" className="link">here</a></p></div>            
          </div>
        </div>
      </div>
    );
  }
  function RenderReturning() {
    return (
      <div className="content">
        <Toaster
          toastOptions={{
            className: "",
            duration: 3000,
            style: {
              border: "2px solid #00cc00",
              padding: "16px",
              color: "#00cc00",
              background: "#181818",
            },
          }}
          containerStyle={{
            position: "absolute",
          }}
        />
        <div className="single-item">
          <div className="left-set">
            <img src="./images/logo.svg" alt="" />
          </div>
          <div className="right-set">
            <div className="subname">Returning Subscriber</div>
            <div className="price">
              <span className="pricesmall">£ </span>20
              <span className="pricesmall"> /per month</span>
            </div>
            <div className="description">
              <p>
                Cancel and restart any time
                <br />
                <br />
                £5 restart fee
                <br />
                <br />
                £10 Licence transfer fee
              </p>
            </div>

            <button onClick={() => checkLicStatus(false)}>Buy Now</button>
          </div>
        </div>
      </div>
    );
  }

  function ChoosePrice(props) {
    const isNew = props.isNew;
    if (isNew) {
      return <RenderNew />;
    }
    return <RenderReturning />;
  }

  let showNew = true;
  if (cookie.user != null && cookie.user.purchased === 1) showNew = false;

  return <ChoosePrice isNew={showNew} />;
}
