import React from "react";
import { FaTwitter, FaDiscord } from "react-icons/fa";

const iconSize = "48px";

const Footer = () => {
  return (
    <div className="footer">
      &copy;2024-<strong>HAWKGEN</strong>
      
      <div className="footer-logo-container">
        <a
          rel="noreferrer"
          className="iconLink"
          target="_blank"
          href="https://mailhawk.co"
        >
          <img
            src="/images/MailHawk_White.svg"
            className="footer-logo hawk-logo"
            alt="MailHawk"
            onMouseOver={(e) => (e.currentTarget.src = "/images/MailHawk.svg")}
            onMouseOut={(e) =>
              (e.currentTarget.src = "/images/MailHawk_White.svg")
            }
          />
        </a>
        <a
          rel="noreferrer"
          className="iconLink"
          target="_blank"
          href="https://twitter.com/HawkGenBot"
        >
          <FaTwitter className="footer-logo twitterhover" size={iconSize} />
        </a>
        <a
          rel="noreferrer"
          className="iconLink"
          target="_blank"
          href="https://discord.gg/X45wrXy3qQ"
        >
          <FaDiscord className="footer-logo discordhover" size={iconSize} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
