import React from "react";
import { config } from "../constants";
import { useCookies } from "react-cookie";
import toast, { Toaster } from "react-hot-toast";

export default function Download() {
  const [cookie] = useCookies(["user"]);
  const [, setDownloadCookie] = useCookies(["download"]);

  createDownloadCookie();
  
  function createDownloadCookie() {
    let data = {'download': true}
    setDownloadCookie("download", data, { path: "/", maxAge: 300, secure: true });
  }

  const customButton = {
    width: "60%",
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, 0%)",
    };

  function checkLogin() {

    if (cookie.user == null) {
      toast("Please login and authenticate via Discord");
    } else if (cookie.user.licenced === 0) {
      toast("You don't have an active licence");
    } else {
      const link =
        config.HAWKDOWNLOAD +
        "disc_id=" +
        cookie.user.disc_id +
        "&token=" +
        cookie.user.token;

      window.open(link, "_blank");
    }
  }

  return (
    <div className="content">
    <Toaster
      toastOptions={{
        className: "",
        duration: 3000,
        style: {
          border: "2px solid #00cc00",
          padding: "16px",
          color: "#00cc00",
          background: "#181818",
        },
      }}
      containerStyle={{
        position: "absolute",
      }}
    />
    <div className="single-item-customer">
      <div className="right-set customer">
        <div className="subname subname-customer">Download</div>
        <div className="description-customer">
          <p>
            <br />
            <br />
            <br />
            <br />
          </p>
        </div>

        <button style={customButton} onClick={() => checkLogin()}>
          Download
        </button>
      </div>
    </div>
  </div>

  );
}
