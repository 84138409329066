import React from "react";
import { useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

export default function Discord() {
  const { search } = useLocation();
  let queryString = search;

  let code = queryString.split("=")[1];

  function copyToClip(){
    navigator.clipboard.writeText(code);
    toast("Copied to Clipboard")
  }

  return (
    <div className="content">
      <Toaster
          toastOptions={{
            className: "",
            duration: 3000,
            style: {
              border: "2px solid #00cc00",
              padding: "16px",
              color: "#00cc00",
              background: "#181818",
            },
          }}
          containerStyle={{
            position: "fixed",
            top: "20%"
          }}
        />
      <div className="authContainer">
      Thank you for authorising HawkGen<br/>
Please enter the code below into the Licence Tab<br/><br/>
        <div className="rectangle" onClick={copyToClip}>{code}</div>
        <span className="authsmall">Click to copy</span>
      </div>
    </div>
  );
}
